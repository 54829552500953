import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { DiscussionEmbed } from "disqus-react"

const Blog = ({ data, location }) => {
  const { html,frontmatter } = data.markdownRemark
  const { title } = frontmatter
  const disqusConfig = {
    shortname: 'nickmccullum',
    config: { identifier: title },
  }

  return (
    <Layout>
      <SEO title={title} />
      <article className="page">
      <h1>{title}</h1>

      <div className="sales-text">
        <p>Hey - Nick here! This page is a free excerpt from my new eBook Pragmatic Machine Learning, which teaches you real-world machine learning techniques by guiding you through 9 projects.</p>
        <p>Since you're reading my blog, I want to offer you a discount. <a href="https://gumroad.com/l/pGjwd/website">Click here to buy the book for 70% off now.</a></p>
      </div>
        <div className="entry" dangerouslySetInnerHTML={{ __html: html }}>
        </div>
        <DiscussionEmbed {...disqusConfig} />
      </article>
    </Layout>
  )
}
export default Blog

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
      fields {
        slug
      }
    }
  }
`